import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { gsap } from 'gsap'
import { Raycaster } from 'three'


/**
 * Base
 */
// Debug
var model
var action
var book, page, page1, page2, page3, page4, page5, page6, page7, page8, page9, page10, page11

let copyText = document.querySelector(".copy-text")
copyText.querySelector("button").addEventListener("click", function(){
    let input = copyText.querySelector("input.text")
    input.select()
    document.execCommand("copy")
    copyText.classList.add("active")
    window.getSelection().removeAllRanges()
    setTimeout(function(){
        copyText.classList.remove("active")
    },2500)
})


let sceneReady = false
const loadingBarElement = document.querySelector('.loading-bar')
const loadingManager = new THREE.LoadingManager(
    () =>
    {
        gsap.delayedCall(0.5, () => {
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0 })
            loadingBarElement.style.display = 'none';
            //loadingBarElement.classList.add('ended')
            //loadingBarElement.style.transform = ''
        })

        window.setTimeout(() => {
            sceneReady = true
        }, 2000)
    },


    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        const progressRatio = itemsLoaded / itemsTotal
        //loadingBarElement.style.transform = 'scaleX(' + progressRatio + ')'
   
    }

)

const video = document.getElementById('video')
const texture = new THREE.VideoTexture(video)
const picTexture = new THREE.TextureLoader().load("/textures/picture/homeLogo.jpeg")
const picTexture2 = new THREE.TextureLoader().load("/textures/picture/homeHover.jpeg")
const tvTexture = new THREE.TextureLoader().load("/textures/picture/tv_screen.png")
const comingTexture = new THREE.TextureLoader().load("/textures/picture/coming.png")
const blueTexture = new THREE.MeshBasicMaterial({ color: 0xff0000 })


texture.minFilter = THREE.LinearFilter;
texture.magFilter = THREE.LinearFilter;

var movieMaterial = new THREE.MeshBasicMaterial({
    map: texture,
    side: THREE.FrontSide,
    toneMapped: false,
})


var picMaterial = new THREE.MeshBasicMaterial({
    map: picTexture,
    toneMapped: false,
    transparent: true,
    opacity: 1
})



var picMaterialh = new THREE.MeshBasicMaterial({
    map: picTexture2,
    side: THREE.FrontSide,
    toneMapped: false,
    transparent: true,
    opacity: 1
})

var tvpicMaterial = new THREE.MeshBasicMaterial({
    map: tvTexture,
    toneMapped: false,
    transparent: true,
    opacity: 1
})

var comingMaterial = new THREE.MeshBasicMaterial({
    map: comingTexture,
    side: THREE.FrontSide,
    toneMapped: false,
    transparent: true,
    opacity: 1
})


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()




/**
 * overlay
 * */
const overlayGeometry = new THREE.PlaneBufferGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    // wireframe: true,
    transparent: true,
    uniforms:
    {
        uAlpha: { value: 1 }
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;

        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)


 // Models
 
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')

const gltfLoader = new GLTFLoader(loadingManager)
gltfLoader.setDRACOLoader(dracoLoader)

const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)



// Point of Interest
const raycaster = new Raycaster()

const points = [
    {
        position: new THREE.Vector3(-0.8, -0.3, -0.5),
        element: document.querySelector('.point-0')
    }
]


let mixer 
var children = []
gltfLoader.load(
    '/models/room12.glb',
    (gltf) => {

        model = gltf.scene
        mixer = new THREE.AnimationMixer(model)
        mixer.clipAction(gltf.animations[0]).play()
        mixer.clipAction(gltf.animations[1]).play()
        mixer.clipAction(gltf.animations[2]).play()
        mixer.clipAction(gltf.animations[3]).play()
        mixer.clipAction(gltf.animations[4]).play()
        mixer.clipAction(gltf.animations[5]).play()
        mixer.clipAction(gltf.animations[6]).play()
        mixer.clipAction(gltf.animations[7]).play()
        mixer.clipAction(gltf.animations[8]).play()
        
        mixer.clipAction(gltf.animations[9]).play()
        //mixer.clipAction(gltf.animations[9]).play()
        book = mixer.clipAction(gltf.animations[11])
        page1 = mixer.clipAction(gltf.animations[12])
        page2 = mixer.clipAction(gltf.animations[13])
        page3 = mixer.clipAction(gltf.animations[10])
        page4 = mixer.clipAction(gltf.animations[14])
        page5 = mixer.clipAction(gltf.animations[15])
        page6 = mixer.clipAction(gltf.animations[16])
        page = mixer.clipAction(gltf.animations[9])
        page7 = mixer.clipAction(gltf.animations[17])
        page8 = mixer.clipAction(gltf.animations[18])
        page9 = mixer.clipAction(gltf.animations[19])
        page10 = mixer.clipAction(gltf.animations[18])
        page11 = mixer.clipAction(gltf.animations[19])
        //page12 = mixer.clipAction(gltf.animations[20])
        
        
        page.setLoop(THREE.LoopOnce)
        page.clampWhenFinished = true
        page10.setLoop(THREE.LoopOnce)
        page10.clampWhenFinished = true
        page11.setLoop(THREE.LoopOnce)
        page11.clampWhenFinished = true
        page7.setLoop(THREE.LoopOnce)
        page7.clampWhenFinished = true
        page8.setLoop(THREE.LoopOnce)
        page8.clampWhenFinished = true
        page9.setLoop(THREE.LoopOnce)
        page9.clampWhenFinished = true
        page3.setLoop(THREE.LoopOnce)
        page3.clampWhenFinished = true
        page4.setLoop(THREE.LoopOnce)
        page4.clampWhenFinished = true
        page5.setLoop(THREE.LoopOnce)
        page5.clampWhenFinished = true
        page6.setLoop(THREE.LoopOnce)
        page6.clampWhenFinished = true
        page2.setLoop(THREE.LoopOnce)
        page2.clampWhenFinished = true
        page1.setLoop(THREE.LoopOnce)
        page1.clampWhenFinished = true
        book.setLoop(THREE.LoopOnce)
        book.clampWhenFinished = true
        //page12.setLoop(THREE.LoopOnce)
        //page12.clampWhenFinished = true
        //action = [book, page1, page2, page]
        //action.setLoop(THREE.LoopOnce)
        //action.clampWhenFinished = true



        model.traverse(function (node) {
            if (node.isMesh) {
                node.castShadow = true
                node.material.environmentMap = environmentMap
                children.push(node)
            }
        })
        model.rotation.y = Math.PI * 1
        model.position.x = 1
        //console.log(model.children[1])
        console.log(model)
        console.log(mixer)
        scene.add(model)


    })

/**
 * Objects
 */
const object1 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.5, 16, 16),
    new THREE.MeshBasicMaterial({ color: '#ff0000' })
)
object1.position.x = 3
object1.position.y = 1
object1.material = texture



const object2 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(1, 16, 16),
    new THREE.MeshBasicMaterial({ color: '#ff0000' })
)

object2.position.y = 3

object2.material = texture

const object3 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.4, 10, 10),
    new THREE.MeshBasicMaterial({ color: '#ff0000' })
)
object3.position.x = -1.3
object3.position.y = 0
object3.position.z = 1
object3.material = texture

const object6 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.1, 10, 10),
    new THREE.MeshBasicMaterial({ color: '#ff0000' })
)

object6.position.x = -1.3
object6.position.y = 13
object6.position.z = 1
object6.material = texture

const object7 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.3, 10, 10),
    new THREE.MeshBasicMaterial({ color: '#ff0000' })
)

object7.position.y = 0.2
object7.position.x = -0.7
object7.position.z = 0.27
object7.material = texture

const object4 = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(1.6, 0.8),
    movieMaterial
)
object4.position.y = 0.93
object4.rotation.y = -Math.PI / 2
object4.position.x = 2.9




const object5 = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(0.8, 0.9),
    picMaterial
)
object5.position.y = 1.3
object5.position.x = 0.1
object5.position.z = -1.57
//object5.scale.x = 1
//object5.scale.y = 2

object5.rotation.Y =  Math.PI / 2

const object8 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.3, 10, 10),
    new THREE.MeshBasicMaterial({ color: '#ff0000' })
)

object8.position.y = 0.2
object8.position.x = 0.7
object8.position.z = 0.27
object8.material = texture

const object9 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.3, 10, 10),
    texture
)

object9.position.y = 0
object9.position.x = -1.6
object9.position.z = 0.27
object9.material = texture
 

const object10 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.2, 0.2),
    new THREE.MeshBasicMaterial({ color: '#ff0000' })
)
object10.position.y = 5
object10.position.x = -0.7
object10.position.z = 0.4
object10.material = texture

const object11 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.3, 8, 8),
    texture
)
object11.position.y = 0
object11.position.x = -1.5
object11.position.z = -0.5
//object11.material = texture

const object12 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.6, 8, 8),
    texture
)
object12.position.y = 0.8
object12.position.x = -1.8
object12.position.z = -1.3
//object11.material = texture

const object13 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.1, 8, 8),
    texture
)
object13.position.y = 5
object13.position.x = 0
object13.position.z = 1
//object11.material = texture

const object14 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.1, 8, 8),
    texture
)
object14.position.y = 5
object14.position.x = 0.2
object14.position.z = 1


scene.add(object1, object2, object3, object4, object5, object6, object7, object8, object9, object10, object11, object12, object13, object14)

/**
 * Update all materials
 */
const updateAllMaterials = () => {
    scene.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            // child.material.envMap = environmentMap
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true

            children.material.needsUpdate = true
            children.castShadow = true
            children.receiveShadow = true
        }
    })
}

/**
 * Environment map
 */
const environmentMap = cubeTextureLoader.load([
    '/textures/environmentMaps/1/px.png',
    '/textures/environmentMaps/1/nx.png',
    '/textures/environmentMaps/1/py.png',
    '/textures/environmentMaps/1/ny.png',
    '/textures/environmentMaps/1/pz.png',
    '/textures/environmentMaps/1/nz.png'
])

environmentMap.encoding = THREE.sRGBEncoding

scene.background = environmentMap
scene.environment = environmentMap
scene.rotation.y = Math.PI * 0.9





/**
 * Floor
 *//*
const floor = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(50, 50),
    new THREE.MeshStandardMaterial({
        color: '#444444',
        metalness: 0,
        roughness: 0.5
    })
)
floor.receiveShadow = true
floor.rotation.x = - Math.PI * 0.5
scene.add(floor)

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 9999
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(5, 5, 5)
scene.add(directionalLight)

// Lights Redo
/*const directionalLight = new THREE.DirectionalLight('#ffffff', 3)
directionalLight.position.set(0.25, 3, -2.25)
scene.add(directionalLight)
directionalLight.castShadow = true
directionalLight.receiveShadow = true

gui.add(directionalLight, 'intensity').min(0).max(10).step(0.001).name('lightIntensity')
gui.add(directionalLight.position, 'x').min(-5).max(5).step(0.001).name('lightX')
gui.add(directionalLight.position, 'y').min(-5).max(5).step(0.001).name('lightY')
gui.add(directionalLight.position, 'z').min(-5).max(5).step(0.001).name('lightZ')

/*const directionalLight2 = new THREE.DirectionalLight('#ff0000', 3)
directionalLight2.position.set(0.25, 3, -2.25)
scene.add(directionalLight2)

gui.add(directionalLight2, 'intensity').min(0).max(10).step(0.001).name('light2Intensity')
gui.add(directionalLight2.position, 'x').min(-5).max(5).step(0.001).name('light2X')
gui.add(directionalLight2.position, 'y').min(-5).max(5).step(0.001).name('light2Y')
gui.add(directionalLight2.position, 'z').min(-5).max(5).step(0.001).name('light2Z')*/


const light = new THREE.DirectionalLight(0xffffff, 0.5, 100)
light.position.set(0, 10, 0)
light.castShadow = true
light.shadow.camera.near = 0.1
light.shadow.bias = -0.001
scene.add(light)




/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(30, sizes.width / sizes.height, 0.1, 99999)
camera.position.set(- 8, 5, 7)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)

controls.target.set(0, 0.5, 0)
controls.enableDamping = true
controls.minDistance = 3
controls.maxDistance = 20
controls.maxPolarAngle = Math.PI / 2
controls.enablePan = false



// Click Event on Meshes
window.addEventListener('click', () => {

    if (currentIntersect) {
        if (currentIntersect.object === object1) {
            console.log('1')
            
            //setTimeout(function() { console.log("test1"); },5000)

            // you can easily disable it by using
            controls.enabled = false

            gsap.to(camera.position, { duration: 0.5, delay: 0.5, x: -1, y: -2, z: 0})
            gsap.to(model.position, { duration: 0.5, delay: 0.5, x: -1.9, y: -0.2, z: 0 })
            gsap.to(object4.position, { duration: 0.5, delay: 0.5, x: 0.01, y: 0.73, z: -0.01 })
            object4.material = tvpicMaterial
            object4.scale.x = 0.99
            object7.position.y = 5
            object1.position.y = 5
            object10.position.y = 0.4
            
            

            /*object4.position.x = -0.1
            object4.position.y = 0.73
            model.position.x = -2
            model.position.y = -0.2*/

            controls.minDistance = 0

            controls.maxPolarAngle = 0

            //Movement
            //camera.position.set(-1, -2, 0)
            controls.maxPolarAngle = Math.PI / 2
        }
        else if (currentIntersect.object === object3) {
            console.log('3')
            setTimeout(function() { console.log("test2"); },4000)
            // you can easily disable it by using
            //controls.enabled = false
            /*
            if ( book != null ) {
                //action.stop()
                //action.play()
                book.stop()
                book.play()
            }

            if ( page2 != null ) {
                //action.stop()
                //action.play()
                page2.stop()
                page2.play()
            } 
            
            if ( page1 != null ) {
                //action.stop()
                //action.play()
                page1.stop()
                page1.play()
            }

            if ( page3 != null ) {
                page3.stop()
                page3.play()
            }
            if ( page4 != null ) {
                page4.stop()
                page4.play()
            }
            if ( page5 != null ) {
                page5.stop()
                page5.play()
            }
            if ( page6 != null ) {
                page6.stop()
                page6.play()
            }

            if ( page7 != null ) {
                page7.stop()
                page7.play()
            }

            if ( page8 != null ) {
                page8.stop()
                page8.play()
            }

            if ( page9 != null ) {
                page9.stop()
                page9.play()
            }

            if ( page10 != null ) {
                page10.stop()
                page10.play()
            }

            if ( page11 != null ) {
                page11.stop()
                page11.play()
            }*/
            object3.position.y = 10
            //book.stop()
            book.play()
            //page1.stop()
            page1.play()
            //page2.stop()
            page2.play()
            //page3.stop()
            page3.play()
            //page4.stop()
            page4.play()
            //page5.stop()
            page5.play()
            //page6.stop()
            page6.play()
            //page7.stop()
            page7.play()
            //page8.stop()
            page8.play()
            //page9.stop()
            page9.play()
            //page10.stop()
            page10.play()
            //page11.stop()
            page11.play()
            //page12.play()
            



            controls.enabled = false

            
            //gsap.to(camera.position, { duration: 0.3, delay: 0.2, x: 0, y: 4, z: 0.1})
            gsap.to(camera.position, { duration: 0.5, delay: 0.5, x: 0, y: 4, z: 0})
            gsap.to(model.position, { duration: 1, delay: 1, x: 2.35, y: 9.5, z: -1 })
            gsap.to(object6.position, { duration: 2, delay: 2.5, x: 0.23, y: 9.5, z: 0.15 })
            //camera.position.set(0, 4, 0)
            //model.position.set(2.35, 9.5, -1)
            //object6.position.set(0, 10, 0)
            controls.minDistance = 10
            controls.maxPolarAngle = Math.PI / 2

            /*
            camera.position.set(- 8, 5, 7)
            controls.target.set(0, 0.5, 0)
            controls.minDistance = 10
            controls.maxPolarAngle = Math.PI / 2*/
        } 
        else if (currentIntersect.object === object7) {
            console.log('7')
            window.open('https://twitter.com/HalfBakedDegens')

        }  
        else if (currentIntersect.object === object11) {
            console.log('11')
            window.open('https://www.instagram.com/halfbakeddegens/')

        } 
        else if (currentIntersect.object === object9) {
            console.log('9')
            /*object10.position.y = 0.5
            object10.position.x = -1.6
            object10.position.z = 0.2*/
            
            window.open('https://discord.gg/cxpMErAC9e')

            /*for (const point of points) {
                
                    point.element.classList.add('visible')
            }*/
            

        } 
        else if (currentIntersect.object === object12) {
            
            controls.minDistance = 3
            
            // you can easily disable it by using
            controls.enabled = false

            gsap.to(camera.position, { duration: 1, delay: 0.5, x: 0, y: 0, z: 2})
            gsap.to(model.position, { duration: 0.5, delay: 0.5, x: 2.7, y: -0.4, z: 2.2 })
            object12.position.y = 10
            object13.position.y = 0.4
            object14.position.y = 0.3
            
        }
        else if (currentIntersect.object === object14){

            
            // you can easily disable it by using
            controls.enabled = true

            for (const point of points) {
                
                point.element.classList.remove('visible')
        }

            
            gsap.to(camera.position, { duration: 0.5, delay: 0.9, x: -8, y: 5, z: 7})
            gsap.to(model.position, { duration: 0.4, delay: 0.7, x: 1, y: 0, z: 0.02 })
            gsap.to(object4.position, { duration: 0.1, delay: 0.4, x: 2.9, y: 0.93, z: 0 })
            gsap.to(object5.position, { duration: 0.6, delay: 0.5, x: 0.1, y: 1.3, z: -1.5 })

            
            object12.position.y = 0.8
            object13.position.y = 5
            object14.position.y = 5

            
            controls.target.set(0, 0.5, 0)
        }

        else if (currentIntersect.object === object13) {


            
            for (const point of points) {
                
                    point.element.classList.add('visible')
            }
        }

        else if (currentIntersect.object === object8) {

            video.play();
				video.addEventListener( 'play', function () {

					this.currentTime = 3;

				}, false );

        }
        else if (currentIntersect.object === object2) {
            console.log('2')
            

            if ( page != null ) {
                //action.stop()
                //action.play()
                page.stop()
                page.play()
            }



        }
        else if (currentIntersect.object === object10) {
            console.log('4')
            controls.enabled = true
            object4.material = movieMaterial
            
            setTimeout(function() { console.log("test4"); },7000)
            gsap.to(camera.position, { duration: 0.5, delay: 0.5, x: -8, y: 5, z: 7})
            gsap.to(model.position, { duration: 0.3, delay: 0.7, x: 1, y: 0, z: 0.02 })
            gsap.to(object4.position, { duration: 0.1, delay: 0.4, x: 2.9, y: 0.93, z: 0 })
            gsap.to(object5.position, { duration: 1, delay: 1, x: 0.1, y: 1.3, z: -1.5 })
            object7.position.y = 0.2
            object1.position.y = 1
            object10.position.y = 0.4


            /*model.position.x = 1
            object4.position.y = 0.93
            object4.position.x = 2.9
            model.position.y = 0*/
            // you can easily disable it by using
            controls.enabled = true
            //camera.position.set(- 8, 5, 7)
            controls.target.set(0, 0.5, 0)
            controls.minDistance = 10
            controls.maxPolarAngle = Math.PI / 2
            controls.enablePan = false
        }
        else if (currentIntersect.object === object5) {
            console.log('5')
            object5.material = picMaterialh
            //Click Event
        }
        
        else if (currentIntersect.object === object6) {
            console.log('6')
            //Click Event
            controls.enabled = true

            book.stop()
            //book.play()
            page1.stop()
            //page1.play()
            page2.stop()
            //page2.play()
            page3.stop()
            //page3.play()
            page4.stop()
            //page4.play()
            page5.stop()
            //page5.play()
            page6.stop()
            //page6.play()
            page7.stop()
            //page7.play()
            page8.stop()
            //page8.play()
            page9.stop()
            //page9.play()
            page10.stop()
            //page10.play()
            page11.stop()
            //page11.play()
            //page12.stop()

            
            setTimeout(function() { console.log("test6"); },4000)
            gsap.to(camera.position, { duration: 0.5, delay: 0.5, x: -8, y: 5, z: 7})
            gsap.to(model.position, { duration: 0.3, delay: 0.7, x: 1, y: 0, z: 0.02 })
            gsap.to(object4.position, { duration: 1, delay: 1, x: 2.9, y: 0.93, z: 0 })
            gsap.to(object5.position, { duration: 1, delay: 1, x: 0.1, y: 1.3, z: -1.5 })
            object3.position.y = 0
            object6.position.x = -1.3
            object6.position.y = 13
            object6.position.z = 1

            

            /*object5.position.y = 1.3
            object5.position.x = 0.1
            object5.position.z = -1.57
            
            model.position.x = 1
            model.position.z = 0.02
            object4.position.y = 0.93
            object4.position.x = 2.9
            model.position.y = 0*/
            // you can easily disable it by using
            controls.enabled = true
            //camera.position.set(- 8, 5, 7)
            controls.target.set(0, 0.5, 0)
            controls.minDistance = 10
            controls.maxPolarAngle = Math.PI / 2
        }

    }
})



/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.shadowMap.type = true
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.physicallyCorrectLights = true
renderer.gammaOutput = true
renderer.outputEncoding = THREE.sRGBEncoding

var pmremGenerator = new THREE.PMREMGenerator(renderer);
pmremGenerator.compileEquirectangularShader();


/**
 * Animate
 */
animate()

function animate() {
    requestAnimationFrame(animate)
    texture.needsUpdate = true
    if (mixer) mixer.update(clock.getDelta())


}

const pointer = new THREE.Vector2()

function onPointerMove(event) {
    pointer.x = (event.clientX / window.innerWidth) *  2 - 1
    pointer.y = - (event.clientY / window.innerHeight) * 2 + 1
}



const clock = new THREE.Clock()
let previousTime = 0
let currentIntersect = null


const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    if(mixer)
    {
        mixer.update(deltaTime)
    }


    // Update controls
    controls.update()


    if (sceneReady) {


        //Go through each point
        for (const point of points) {
            const screenPosition = point.position.clone()
            screenPosition.project(camera)


            raycaster.setFromCamera(pointer, camera)
            const objectsToTest = [object5]
            const intersects = raycaster.intersectObjects(scene.children, true)


            for (const object of objectsToTest) {
                if (object   === object5) {
                    object5.material = picMaterial
                }
            }

            for (const intersect of intersects) {
                if (intersect.object === object5) {
                    object5.material = picMaterialh
                }
            }

            

            

            if (intersects.length) {
                if (currentIntersect === null) {
                    //console.log('mouse enter')
                }
                currentIntersect = intersects[0]
            }
            else {
                if (currentIntersect) {
                    //console.log('mouse leave')
                }

                currentIntersect = null
            }

            /*
            if (intersects.length === 0) {
                point.element.classList.add('visible')
            }
            else {
                const intersectionDistance = intersects[0].distance
                const pointDistance = point.position.distanceTo(camera.position)

                if (intersectionDistance < pointDistance) {
                    point.element.classList.remove('visible')
                }
                else {
                    point.element.classList.add('visible')
                }
                point.element.classList.remove('visible')
            }

            const translateX = screenPosition.x * sizes.width * 0.5
            const translateY = - screenPosition.y * sizes.height * 0.5
            point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
            */
        }

    }
    

    // Render
    renderer.render(scene, camera)

    //for pointer
    window.addEventListener('pointermove', onPointerMove)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}


tick()